import React from 'react'
import { LoginLayoutWrapper, FooterWrap } from './style'
import { getNavBarNOAuthLogo, getBrand } from '@shared/brand'
import { Link, useLocation } from 'react-router-dom'
import { LOGIN } from 'app/navigation/url-constants'
import { useRecoilValue } from 'recoil'
import { showEmailPasswordLoginLinkAtom } from 'app/traces/state/atoms'
import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import LanguageSwitcher from '_metronic/layout/components/aside/aside-menu/LanguageSwitcher'

const LoginLayout = ({ children }) => {
    const {
        LL: { login }
    } = useI18nContext()
    const { helpEmail } = getBrand().urls
    const brandLogo = getNavBarNOAuthLogo()
    const { pathname } = useLocation()
    const showEmailPasswordLoginLink = useRecoilValue(showEmailPasswordLoginLinkAtom)
    const isEmailPass = pathname === LOGIN.EMAIL_PASS
    const otherLoginTypeLink = isEmailPass ? LOGIN.INDEX : LOGIN.EMAIL_PASS
    const otherLoginTypeText = isEmailPass ? login.phoneNumberLogin() : login.emailLogin()

    return (
        <LoginLayoutWrapper>
            <div>
                <img src={brandLogo.img} style={brandLogo.style} />
            </div>

            {children}

            <FooterWrap>
                <a href={otherLoginTypeLink}>{isEmailPass ? login.useNumber() : login.useEmail()}</a>
                <span>{login.troubleLoggingIn()}</span>
                <div>
                    <a href={'mailto:' + helpEmail}>{login.contactSupport()}</a>
                    {showEmailPasswordLoginLink && <Link to={otherLoginTypeLink}>{otherLoginTypeText}</Link>}
                </div>
                <div className="switcher">
                    <LanguageSwitcher size="medium" />
                </div>
            </FooterWrap>
        </LoginLayoutWrapper>
    )
}

export default LoginLayout
