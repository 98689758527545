import styled from 'styled-components'
import * as colors from '@shared/colors'
import { getBrand } from '@shared/brand'

const milk_gray = colors.milk_gray
const black = colors.black_sweeply
const brandColor = getBrand().navBarColor
const milk_white = colors.milk_white

const left = ({ asideIsOpen }) => {
    return asideIsOpen ? '0' : '-248px'
}

const asideWidth = ({ asideIsOpen }) => {
    return asideIsOpen ? '248px' : '80px'
}

const toggleButtonPosition = ({ asideIsOpen }) => (asideIsOpen ? 'unset' : 'absolute')
const toggleButtonTop = ({ asideIsOpen }) => (asideIsOpen ? 'unset' : '50%')
const toggleButtonLeft = ({ asideIsOpen }) => (asideIsOpen ? 'unset' : '50%')
const toggleButtonTransform = ({ asideIsOpen }) => (asideIsOpen ? 'unset' : 'translate(-50%, -50%)')

export const AsideWrap = styled.div`
    max-width: ${asideWidth};
    width: ${asideWidth};
    height: 100vh;
    background-color: ${milk_white};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: fixed;
    z-index: 1002;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    

    #kt_aside,
    #kt_aside_menu {
        padding-bottom: 42px;
        overflow-y: scroll;
        background: unset !important;
        ::-webkit-scrollbar {
            display: none;
        }
    }

    #kt_aside {
        max-width: ${asideWidth};
        width: ${asideWidth};
        border-right: 1px solid ${milk_gray};
        padding-top: 18px;
    }

    #kt_brand {
        padding: 0 17px;
        position: relative;

        .brand-logo {
            position: ${toggleButtonPosition};
            left: ${toggleButtonLeft};
            top: ${toggleButtonTop};
            transform: ${toggleButtonTransform};
            transition: opacity 0.3s ease-in-out;
            z-index: 2;

            > img {
                display: block !important;
            }

            &:hover {
                opacity: 0;
            }
        }
    }

    .brand-toggle {
        width: 35px !important;
        height: 35px !important;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        position: ${toggleButtonPosition};
        top: ${toggleButtonTop};
        left: ${toggleButtonLeft};
        transform: ${toggleButtonTransform};

        &:hover {
            opacity: 1;
        }
    }

    #kt_aside_menu {
        .menu-item {
            .menu-link {
                width: 100%;
                padding: 0 33px;
                column-gap: 20px;
                background-color: ${milk_white} !important;
                justify-content: 'flex-start';

                > span {
                    width: auto;
                    margin: 0 !important;
                    font-weight: 450;
                    font-size: 1.231rem;
                    line-height: 16px;
                    color: ${black};
                    opacity: 0.75;

                    .nav-item-active {
                        color: ${brandColor};
                        opacity: 0.75;
                    }
                }

                .menu-icon {
                    color: ${black};
                    opacity: 0.75;
                }

                &:hover {
                    > span {
                        opacity: 1 !important;
                    }

                    i {
                        color: ${black} !important;
                        opacity: 1 !important;
                    }
                }
            }

            .active:hover .menu-icon {
                color: ${brandColor} !important;
                opacity: 1 !important;
            }
        }

        .menu-subnav {
            .menu-toggle {
                min-height: 25px !important;
                column-gap: 2px;
                padding-left: 60px;

                .menu-bullet {
                    &::before {
                        content: '';
                        width: 0.462rem;
                        height: 0.462rem;
                        border-radius: 50%;
                        display: inline-block;
                        background-color: ${black};
                        vertical-align: middle;
                        opacity: 0.75;
                    }

                    > span {
                        display: none;
                    }
                }

                .menu-text {
                    font-weight: 450 !important;
                    font-size: 1.038rem;
                    line-height: 16px;
                    color: ${black};
                    opacity: 0.75 !important;
                }
            }
        }

        .menu-subnav .active {
            .menu-bullet::before,
            .menu-text {
                color: ${brandColor} !important;
                opacity: 1 !important;
                font-weight: 700 !important;
            }

            .menu-bullet::before {
                background-color: ${brandColor} !important;
            }
        }

        .nav-item-active {
            color: ${brandColor} !important;
            font-weight: 700 !important;

            span,
            i {
                opacity: 1 !important;
            }
        }
    }

    @media (max-width: 991px) {
        left: ${left};
        transition: left 0.3s ease;

        #kt_aside {
            left: ${left};
        }
    }
`
