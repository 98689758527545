import styled from 'styled-components'
import * as colors from '@shared/colors'

export const SubNavItemsWrap = styled.div`
    > h6 {
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 13px;
    }
    ${({ asideIsOpen }) => {
        if (!asideIsOpen) {
            return `.menu-link {
                        padding: 3px 0 !important;
                        display: unset !important;
                    }
                    .menu-text {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 0.9rem !important;
                        line-height: 11px;
                    }`
        }
    }}
`

export const SubscriptionIndicatorWrapper = styled.div`
    z-index: 100;
    padding: 0 33px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    ${({ tracesView }) => {
        if (tracesView) {
            return `padding: 0 15px;`
        }
    }}
`
export const TrialPeriodIndicator = styled.span`
    white-space: nowrap;
    color: ${colors.red_sweeply} !important;
    font-weight: 600;
    font-size: 1.038rem;
`
export const SubscriptionName = styled.div`
    white-space: initial;
    cursor: pointer;
    color: ${colors.black_sweeply};
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 14px;
    ${({ tracesView }) => {
        if (tracesView) {
            return `margin-bottom: 2px;`
        }
    }}
`

export const IntegrationLogo = styled.img({
    paddingLeft: '33px',
    '& path': {
        fill: colors.black_sweeply
    }
})

export const IntegrationLink = styled.div({
    '& path': {
        fill: colors.black_sweeply
    }
})
export const IntegrationContainer = styled.div({
    cursor: 'pointer',
    position: 'relative',
    width: 'fit-content',
    transition: 'all 0.3s', // Optional: Add a transition for smooth effect
    opacity: 0.25,
    '&:hover': {
        opacity: 1
    }
})

export const IntegrationAction = styled.img({
    fill: colors.black_sweeply,
    position: 'absolute',
    right: '-21px',
    top: '-9px'
})
