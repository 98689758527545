import {IssueItem, IssueStruct, TaskStruct} from './firestore-structs'

import {DocumentData, DocumentReference, Firebase, Query, WriteBatch} from "./firebase";

type FirebaseQuery = (firebase: Firebase) => Query<DocumentData>
type FirebaseDoc = (firebase: Firebase) => DocumentReference<DocumentData>


const genericQuery = <T>(query: FirebaseQuery) => {
    return async (firebase: Firebase) => {
        const tasksRef = await query(firebase).get()
        return tasksRef.empty ? [] : tasksRef.docs.map(t => t.data() as T)
    }
}
const genericQueryDirect = <T>(query: Query<DocumentData>) => {
    return async () => {
        const tasksRef = await query.get()
        return tasksRef.empty ? [] : tasksRef.docs.map(t => t.data() as T)
    }
}

const genericGet = <T>(query: FirebaseDoc) => {
    return async (firebase: Firebase) => {
        const tasksRef = await query(firebase).get()
        return tasksRef.data() as T
    }
}

const querySnapshotListener = <T>(query: FirebaseQuery) => {
    return (firebase: Firebase) => {
        return {
            onSnapshot: (callback: (tasks: T[]) => void, error?: (e: unknown) => void) =>
                query(firebase).onSnapshot(tasksRef => {
                    callback((!tasksRef || tasksRef.empty) ? [] : tasksRef.docs.map(t => t.data() as T)),
                        (e: unknown) => {
                            if (error) {
                                error(e)
                            }
                        }
                })
        }
    }
}
const querySnapshotListenerDirect = <T>(query: Query<DocumentData>) => {
    return {
        onSnapshot: (callback: (tasks: T[]) => void, error?: (e: unknown) => void) =>
            query.onSnapshot(tasksRef => {
                callback((!tasksRef || tasksRef.empty) ? [] : tasksRef.docs.map(t => t.data() as T)),
                    (e: unknown) => {
                        if (error) {
                            error(e)
                        }
                    }
            })
    }
}

const docSnapshotListener = <T>(query: FirebaseDoc) => {
    return (firebase: Firebase) => {
        return {
            onSnapshot: (callback: (tasks: T | undefined) => void, error?: (e: unknown) => void) =>
                query(firebase).onSnapshot(tasksRef => {
                    callback(tasksRef?.data() as T | undefined),
                        (e: unknown) => {
                            if (error) {
                                error(e)
                            }
                        }
                })
        }
    }
}
export const issuesQuery = genericQuery<IssueStruct>
// eslint-disable-next-line prettier/prettier
export const tasksQuery = genericQuery<TaskStruct>
export const tasksQueryDirect = genericQueryDirect<TaskStruct>
export const issueItemsQuery = genericQuery<IssueItem>

export const tasksGet = genericGet<TaskStruct>
export const issuesGet = genericGet<IssueStruct>
export const issuesSnapshotListener = querySnapshotListener<IssueStruct>
export const tasksSnapshotListener = querySnapshotListener<TaskStruct>
export const tasksSnapshotListenerDirect = querySnapshotListenerDirect<TaskStruct>
export const issueItemsSnapshotListener = querySnapshotListener<IssueItem>

export const tasksDocSnapshotListener = docSnapshotListener<TaskStruct>
export const issuesDocSnapshotListener = docSnapshotListener<IssueStruct>
export const issueItemsDocSnapshotListener = docSnapshotListener<IssueItem>

export async function batchCommit(batch: WriteBatch, locationText: string, actionText: string) {
    await batch.commit().catch(error => {
        console.error(`(${locationText}) `, error)
        throw new Error(`Error while ${actionText}, please try again`)
    })
}
