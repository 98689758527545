import { Select } from '@material-ui/core'
import { MOBILE_BREAKPOINT } from 'app/utils/constants'
import styled from 'styled-components'

interface StyledSelectProps {
    fontSize?: number
}

export const SwitcherWrapper = styled.div`
    margin: 9px 33px;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        margin: 9px 20px;
    }
`

export const StyledSelect = styled(Select)<StyledSelectProps>`
    border-bottom: none;
    box-shadow: none;
    .MuiMenu-list{
        max-height: 140px
    }

    .MuiSelect-root {
        font-family: 'Circular Std';
    }
    
    .MuiSelect-select{
        font-size: ${props => `${props.fontSize}px` || '12px'};
    }

    .MuiSelect-select:focus {
        background-color: transparent;
    }
`
