import { MenuItem, Size } from '@material-ui/core'
import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { Locales } from '@shared-snap/i18n/i18n-types'
import { loadLocaleAsync } from '@shared-snap/i18n/i18n-util.async'
import React, { ChangeEvent } from 'react'
import { languages } from '@shared-snap/snap/utils'
import { StyledSelect, SwitcherWrapper } from './style'

const LanguageSwitcher = ({ size = 'small' }: { size?: Size }) => {
    const { locale, setLocale } = useI18nContext()
    const fontSize = size === 'small' ? 14 : 16
    const onLocaleSelected = async ({ target }: ChangeEvent<{ value: string | unknown }>) => {
        const locale = target.value as Locales
        localStorage.setItem('lang', locale)
        await loadLocaleAsync(locale)
        setLocale(locale)
    }

    return (
        <SwitcherWrapper>
            <StyledSelect fontSize={fontSize} value={locale} onChange={onLocaleSelected} autoWidth disableUnderline  MenuProps={{
        PaperProps: {
            style: {
                maxHeight: 140, 
                overflowY: 'auto', 
            },
        },
    }}>
                {languages.map(({ code, flag }) => (
                    <MenuItem key={code} value={code} style={{ fontFamily: 'inherit' }}>
                        {`${flag} ${code === 'uk' ? 'UA' : code.toUpperCase()}`}
                    </MenuItem>
                ))}
            </StyledSelect>
        </SwitcherWrapper>
    )
}

export default LanguageSwitcher
