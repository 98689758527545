import styled from 'styled-components'
import * as colors from '@shared/colors'
import { MOBILE_BREAKPOINT } from '../../../utils/constants'

const black = colors.black_sweeply
const white = colors.white
const blue = colors.medium_blue
const gray = colors.gray_sweeply

export type CodeInputContainerProps = {
    strokeLength: number
    isMobile: boolean
    value: string
    MAX_LENGTH: number
}

export const CodeInputContainer = styled.div<CodeInputContainerProps>`
    display: flex;
    flex-direction: column;
    row-gap: 9px;
    width: 377px;
    position: relative;

    label {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: ${black};
        margin: 0;
    }

    input {
        width: 100%;
        height: 74px;
        background-color: ${white};
        border: 2px solid ${blue};
        border-radius: 8px;
        padding-left: 51px;
        display: flex;
        align-items: center;
        font-weight: 450;
        font-size: 24px;
        line-height: 40px;
        letter-spacing: ${({ strokeLength, isMobile }) => {
            let space = isMobile ? 26.5 : 38
            for (let i = 0; i < strokeLength; i++) space += 1
            return `${space}px`
        }};
        caret-color: ${({ value, MAX_LENGTH }) => (value.length === MAX_LENGTH ? 'transparent' : 'auto')};

        &:focus {
            outline: none;
        }
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        width: 295px;

        input {
            padding-left: 39px;
        }
    }
`

export type UnderscoreProps = { filled: boolean; leftPosition: number }

export const Underscore = styled.div<UnderscoreProps>`
    width: 36px;
    height: 2px;
    background: ${({ filled }) => (filled ? blue : gray)};
    border-radius: 2px;
    position: absolute;
    left: ${({ leftPosition }) => `${leftPosition}px`};
    bottom: 18px;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        width: 28.24px;
    }
`
