import { DateOptionType } from './types'
import moment from 'moment-timezone'

export const dateOptions = (timezone: string): DateOptionType[] => [
    {
        dateFilter: 'Today',
        dateFilterType: 'date',
        startDate: moment.tz(timezone).startOf('day').valueOf(),
        endDate: moment.tz(timezone).startOf('day').valueOf()
    },
    {
        dateFilter: 'Yesterday',
        dateFilterType: 'date',
        startDate: moment.tz(timezone).add(-1, 'days').startOf('day').valueOf(),
        endDate: moment.tz(timezone).add(-1, 'days').startOf('day').valueOf()
    },
    {
        dateFilter: 'This month',
        dateFilterType: 'period',
        startDate: moment.tz(timezone).startOf('month').valueOf(),
        endDate: moment.tz(timezone).startOf('day').valueOf()
    },
    {
        dateFilter: 'Last month',
        dateFilterType: 'period',
        startDate: moment.tz(timezone).add(-1, 'months').startOf('month').valueOf(),
        endDate: moment.tz(timezone).add(-1, 'months').endOf('month').valueOf()
    },
    {
        dateFilter: 'Next week',
        dateFilterType: 'period',
        startDate: moment.tz(timezone).startOf('day').valueOf(),
        endDate: moment.tz(timezone).startOf('day').add(1, 'week').valueOf()
    }
]
