import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'
import { checkIsActive } from '../../../../_helpers'
import { AuthContext } from '../../../../../app/modules/Auth/AuthContext'
import { filterNavItems } from '../../../../../app/navigation/nav-items'
import { useRecoilValue } from 'recoil'
import { asideMenuIsOpen } from '../state'
import Tooltip from '../../../../../app/components/tooltips/WhiteTooltip/index'
import { SubNavItemsWrap } from './style'
import { useI18nContext } from '@shared-snap/i18n/i18n-react'

export function AsideMenuList({ layoutProps }) {
    const {LL: {navigation}} = useI18nContext();
    const location = useLocation()
    const history = useHistory()
    const { user, organization } = useContext(AuthContext)
    const [clickedSubmenu, setClickedSubmenu] = useState(null)
    const asideIsOpen = useRecoilValue(asideMenuIsOpen)
    const toggleBtnRef = useRef(null)

    useEffect(() => {
        toggleBtnRef.current = document.getElementById('kt_aside_toggle')
    }, [])

    useEffect(() => {
        if (asideIsOpen) {
            const submenuItem = document.getElementById('menu-submenu' + clickedSubmenu?.path)
            if (submenuItem) {
                submenuItem.parentElement.classList.add('menu-item-open')
            }
        }
    }, [asideIsOpen, clickedSubmenu?.path])

    const getMenuItemActive = url => {
        return checkIsActive(location, url) ? 'nav-item-active' : ''
    }

    const filteredNavItems = filterNavItems(user, organization)
    const onMenuItemClick = item => {
        if (item.subMenuItems.length === 0) {
            const submenus = document.querySelectorAll('.menu-submenu')

            submenus.forEach(submenu => {
                submenu.style.display = 'none'
                submenu.style.overflow = 'hidden'
            })
        } else if (!asideIsOpen) {
            setClickedSubmenu(item)
            document.body.classList.remove('aside-minimize')
        }
    }
    const onSubMenuClick = (item, menuItemsArr) => {
        const subMenu = document.getElementById('menu-submenu' + item.path)

        if (item.path !== history.location.pathname) {
            subMenu.style.display = 'block'
            subMenu.style.overflow = 'auto'
        }

        const filteredMenuItems = menuItemsArr.filter(item => item.path !== item.path)

        filteredMenuItems.forEach(item => {
            const subMenu = document.getElementById('menu-submenu' + item.path)

            if (subMenu) {
                subMenu.style.display = 'none'
                subMenu.style.overflow = 'hidden'
            }
        })
    }

    return (
        <>
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                {filteredNavItems.map((i, _, menuItemsArr) => {
                    const icon = i.iconNotSpecta ? i.icon : `specta-${i.icon}`
                    const path = i.external ? { pathname: i.path } : i.path
                    const target = i.external ? '_blank' : ''

                    if (i.subMenuItems.length > 0) {
                        const subNavItem = (
                            <SubNavItemsWrap asideIsOpen={asideIsOpen}>
                                {!asideIsOpen && <h6>{navigation[i.name]()}</h6>}
                                {i.subMenuItems.map((subItem, index) => {
                                    return (
                                        <div key={subItem.name + subItem.path + index}>
                                            {asideIsOpen && (
                                                <li key={subItem.path} className="menu-item  menu-item-parent" aria-haspopup="true">
                                                    <span className="menu-link">
                                                        <span className="menu-text">{navigation[subItem.name]()}</span>
                                                    </span>
                                                </li>
                                            )}

                                            <li
                                                className={`menu-item menu-item-submenu ${getMenuItemActive(subItem.path)}`}
                                                aria-haspopup="true"
                                                data-menu-toggle="hover"
                                                onClick={() => onSubMenuClick(i, menuItemsArr)}>
                                                <NavLink className={'menu-link menu-toggle'} to={subItem.path}>
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text font-weight-base">{navigation[subItem.name]()}</span>
                                                </NavLink>
                                            </li>
                                        </div>
                                    )
                                })}
                            </SubNavItemsWrap>
                        )
                        const submenu = (
                            <li
                                key={'sub-menu' + i.name + i.path + _}
                                className={`menu-item menu-item-submenu ${getMenuItemActive(i.path)}`}
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                                onClick={() => onMenuItemClick(i)}>
                                <NavLink className="menu-link menu-toggle" to={path} target={target}>
                                    <span className="svg-icon menu-icon" style={{ minWidth: 18 }}>
                                        <i
                                            className={`menu-icon ${icon} ${getMenuItemActive(i.path)} icon-${i.iconSize}`}
                                            style={i.style}
                                        />
                                    </span>
                                    <span className={`ml-3 menu-text font-weight-base ${getMenuItemActive(i.path)}`}>{navigation[i.name]()}</span>
                                </NavLink>

                                <div id={'menu-submenu' + i.path} className="menu-submenu">
                                    <i className="menu-arrow" />
                                    <ul className="menu-subnav">{subNavItem}</ul>
                                </div>
                            </li>
                        )

                        return asideIsOpen ? (
                            submenu
                        ) : (
                            <Tooltip key={'tooltip' + i.name + i.path + _} placement={'right'} content={navigation[i.name]()}>
                                {submenu}
                            </Tooltip>
                        )
                    } else {
                        const navItem = (
                            <li
                                onClick={() => onMenuItemClick(i)}
                                key={i.name + i.path + _}
                                className={`menu-item ${getMenuItemActive(i.path)}`}
                                aria-haspopup="true">
                                <NavLink className="menu-link" to={path} target={target}>
                                    <span className="svg-icon menu-icon" style={{ minWidth: 18 }}>
                                        <i
                                            className={`menu-icon ${icon} ${getMenuItemActive(i.path)} icon-${i.iconSize}`}
                                            style={i.style}
                                        />
                                    </span>
                                    <span className={`ml-3 menu-text  font-weight-base ${getMenuItemActive(i.path)}`}>{navigation[i.name]()}</span>
                                </NavLink>
                            </li>
                        )

                        return asideIsOpen ? (
                            navItem
                        ) : (
                            <Tooltip key={'tooltip' + i.name + i.path + _} placement={'right'} content={navigation[i.name]()}>
                                {navItem}
                            </Tooltip>
                        )
                    }
                })}
            </ul>
        </>
    )
}
