import React, { useState } from 'react'
import LogoutIcon from './LogoutIcon'
import { LogoutButtonWrap } from './style'
import Alert from '../../../../../../src/app/components/alerts/Alert'
import {logout} from '../../../../../app/utils/firebase'
import { useI18nContext } from '@shared-snap/i18n/i18n-react'

const LogoutButton = ({ asideIsOpen = true, padding  }) => {
    const {LL: {navigation}} = useI18nContext()
    const [showLogoutModal, setShowLogoutModal] = useState(false)

 
    return (
        <LogoutButtonWrap padding={padding} onClick={() => setShowLogoutModal(true)}>
            <div>
                <LogoutIcon />
                {asideIsOpen && <span>{navigation.logout()}</span>}
            </div>

            {showLogoutModal && (
                <Alert
                    title={navigation.logoutAlert()}
                    text={navigation.logoutAlertBody()}
                    onConfirm={logout}
                    onCancel={() => setShowLogoutModal(false)}
                />
            )}
        </LogoutButtonWrap>
    )
}

export default LogoutButton
