import * as gc from './txt-constants'
import { OrgStruct, UserAnalytics, UserStruct } from './firestore-structs'
import { Firebase, FirebaseFirestore } from './firebase'

export const ORGCODE_SUCCESS = ['login_orgcode_success']
export const ORGCODE_FAILED = ['login_orgcode_failed']
export const LOGIN_PHONE = ['login_with_phone']
export const LOGIN_PHONE_FAILED = ['login_with_phone_failed']
export const LOGIN_EMAIL_PASSWORD = ['login_with_email_password']
export const LOGIN_EMAIL_PASSWORD_FAILED = ['login_with_email_password_failed']
export const LOGIN_PHONE_VERIFICATION = ['login_with_phone_verification']
export const LOGIN_PHONE_VERIFICATION_FAILED = ['login_with_phone_verification_failed']
export const LOGOUT = ['logout', 'user_key']

export const ISSUE_OPEN = ['issue_open', 'key']
export const ISSUE_MINIBAR_CREATE = ['issue_minibar_create', 'key']
export const ISSUE_CREATE = ['issue_create', 'key']
export const ISSUE_CAPTURE = ['issue_capture', 'deviceid', 'width', 'height']
export const ISSUE_DELETE = ['issue_delete', 'key']
export const ISSUE_ADDTO = ['issue_addto', 'key', 'issueCounter']
export const ISSUE_RENAME = ['issue_rename', 'old_name', 'new_name']
export const ISSUE_STATUS = ['issue_status', 'status', 'key']
export const ISSUE_SHARE = ['issue_share', 'key']
export const ISSUE_ASSIGN = ['issue_assign', 'type']
export const ISSUE_CHANGE_UNIT = ['issue_change_unit', 'old_name', 'new_name']

export const ITEM_CAPTURE = ['item_capture', 'type']
export const ITEM_IMAGE = [
    'item_image',
    'devicebrand',
    'deviceWidth',
    'deviceHeight',
    'imageWidth',
    'imageHeight',
    'cropX',
    'cropY',
    'cropWidth',
    'cropHeight',
    'resizeWidth',
    'resizeHeight',
    'resizeRotation',
    'androidImageFix'
]
export const ITEM_REMOVE = ['item_remove', 'key']
export const ITEM_SHARE = ['item_share', 'issue_key']

export const USER_CREATE = ['user_create', 'key']
export const USER_EDIT = ['user_edit', 'key']
export const USER_DELETE = ['user_delete', 'key']

export const AREA_CREATE = ['area_create', 'key']
export const AREA_EDIT = ['area_edit', 'key']
export const AREA_DELETE = ['area_delete', 'key']
export const AREA_CLEANING_STATUS = ['area_cleaning_status', 'status']
export const AREA_CLEANING_ASSIGN = ['area_cleaning_assign', 'date']
export const AREA_MANUAL_CHECKIN = ['area_manual_checkin', 'key']
export const AREA_CAMERA_CHECKIN = ['area_camera_checkin', 'key']
export const AREA_CAMERA_SCAN = ['area_camera_scan']
export const AREA_ACTIVITY = ['area_activity_']

export const PUSH_NOTIFICATION_OPEN = ['push_notification_open', 'issue', 'item', 'message']

export function tabName(tabNumber: number) {
    let name = null
    switch (tabNumber) {
        case 0:
            name = gc.TASKS_TAB
            break
        case 1:
            name = gc.HOUSEKEEPING_TAB
            break
        case 2:
            name = gc.CAMERA_TAB
            break
        case 3:
            name = gc.ISSUES_TAB
            break
        default:
            name = 'unknownTabNumber'
    }
    return name
}

export async function startSession(firebase: Firebase) {
    if (process.env.NODE_ENV === 'development') {
        console.log('==== Analytics DISABLED')
        if (firebase.analytics) {
            await firebase.analytics().setAnalyticsCollectionEnabled(false)
        }
    } else {
        console.log('==== Analytics ENABLED')
        if (firebase.analytics) {
            await firebase.analytics().setAnalyticsCollectionEnabled(true)
        }
    }
}

export async function setUser(
    firebase: Firebase,
    user: UserStruct & {
        type?: string
        authRole?: string
    },
    organization: OrgStruct
) {
    if (!user.key) {
        console.log('NO ANALYTICS USER ID')
        return
    }
    if (firebase.analytics) {
        await firebase.analytics().setUserId(user.key)
    }

    let userType = 'customer'
    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV === 'development' || user.phoneNumber.substring(0, 6) === '+354999') {
        userType = 'developer'
    } else if (user.type !== undefined) {
        userType = user.type
    }
    if (firebase.analytics) {
        await firebase.analytics().setUserProperties({ userType })
    }

    if (user.organizationKey) {
        if (firebase.analytics) {
            await firebase.analytics().setUserProperties({ organizationKey: user.organizationKey })
        }
    }
    if (user.authRole) {
        if (firebase.analytics) {
            await firebase.analytics().setUserProperties({ authRole: user.authRole })
        }
    }
    if (organization.subscription) {
        if (firebase.analytics) {
            await firebase.analytics().setUserProperties({ subscription: organization.subscription })
        }
    }
    if (organization.pms) {
        if (firebase.analytics) {
            await firebase.analytics().setUserProperties({ pms: organization.pms })
        }
    }
    if (organization.brand) {
        if (firebase.analytics) {
            await firebase.analytics().setUserProperties({ brand: organization.brand })
        }
    }
}

export async function logEvent(firebase: Firebase, event: string, parameter: string | null, value?: string | number | boolean | null) {
    let objParameter = {}
    if (value !== undefined && value !== null) {
        objParameter = {
            [parameter!]: String(value)
        }
    }
    if (firebase.analytics) {
        await firebase.analytics().logEvent(event, objParameter)
    }
}

export async function logScreenView(firebase: Firebase, screenName: string) {
    //FIXME: Property 'logScreenView' does not exist on type 'Analytics'
    //@ts-ignore
    await firebase.analytics().logScreenView({
        screen_name: screenName
    })
    logEvent(firebase, `page_${screenName}`, null, null)
}

export async function setCurrentScreen(firebase: Firebase, screenName: string) {
    if (firebase.analytics) {
        await firebase.analytics().setCurrentScreen(screenName as string, screenName)
    }
    await logEvent(firebase, `page_${screenName}`, null, null)
}

export function logEventParams(firebase: Firebase, event: string, objParameters: unknown) {
    if (firebase.analytics) {
        firebase.analytics().logEvent(event, objParameters)
    }
}

export class Analytics {
    firestore: FirebaseFirestore

    constructor(firebase: FirebaseFirestore) {
        this.firestore = firebase
    }

    async updateWebUserFingerprint(
        info: {
            userKey: string
            orgKey: string
        } & Omit<UserAnalytics['web'], 'updated'>
    ) {
        await this.firestore
            .collection<UserAnalytics>('user-analytics')
            .doc(info.userKey)
            .set(
                {
                    organizationKey: info.orgKey,
                    web: {
                        sha: info.sha,
                        agent: info.agent,
                        browser: info.browser,
                        version: info.version,
                        locale: info.locale,
                        updated: Date.now()
                    }
                },
                { merge: true }
            )
    }
    async updateMobileUserFingerprint(
        info: {
            userKey: string
            orgKey: string
        } & Omit<UserAnalytics['mobile'], 'updated'>
    ) {
        await this.firestore
            .collection<UserAnalytics>('user-analytics')
            .doc(info.userKey)
            .set(
                {
                    organizationKey: info.orgKey,
                    mobile: {
                        applicationVersion: info.applicationVersion,
                        buildVersion: info.buildVersion,
                        deviceBrand: info.deviceBrand,
                        deviceID: info.deviceID,
                        runtimeVersion: info.runtimeVersion,
                        sha: info.sha,
                        platform: info.platform,
                        update: info.update,
                        locale: info.locale,
                        updated: Date.now()
                    }
                },
                { merge: true }
            )
    }
}
