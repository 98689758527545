import React, { Suspense, useContext, useEffect, useRef, useState } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { ContentRoute } from '../_metronic/layout'
import LoadingView from './utils/loading-view'

import { AreasProvider } from './modules/Areas/AreasContext'
import RulesPage from './modules/Rules/pages/RulesPage'
import { RuleEdit } from './modules/Rules/pages/rule-edit/RuleEdit'
import HouseDashboard from './housekeeping/dashboard/dashboard'
import { Reports } from './components/reports/issues'
import { ErrorPage } from './error/404'

import { AuthContext } from './modules/Auth/AuthContext'
import { RulesProvider } from './modules/Rules/RulesContext'

import { authPageStyle, height, width } from './utils/styles.js'
import AreasPage from './modules/Areas/pages/AreasPage'

import AccountsPage from './modules/Accounts/pages/AccountsPage'
import AccountPage from './modules/Accounts/pages/account-page/AccountPage'
import TasksPage from './tasks/pages/TasksPage'
import UsersPage from './modules/Users/pages/UsersPage'
import ReportsPage from './modules/Reports/pages/housekeeping-report/ReportsPage.js'
import IssuesPage from './issues/pages/IssuesPage'
import DashboardPage from './modules/Partner/dashboard/DashboardPage'
import { SubscriptionContext } from './modules/Subscriptions/SubscriptionContext'
import { UpgradeModal } from './modules/Subscriptions/UpgradeModal'
import GuestsPage from './modules/OptionalHousekeeping/pages/guests-page/GuestsPage'
import { HOUSEKEEPING, ISSUES, REPORTS, SETTINGS, TASKBOARD } from './navigation/url-constants'
import OptionalPage from './modules/Reports/pages/optional-cleaning/OptionalPage.js'
import HousekeepingDashboard from './modules/HousekeepingDashboard'
import WorkloadPage from './modules/Reports/pages/workload-report/index'
import { useFeatureToggle } from './features'
import HousekeepingProjectionView from './housekeeping/projections'
import { useRecoilValue } from 'recoil'
import { integrationParamsAtom } from './traces/state/atoms'
import { useTracesIntegrationParams } from './traces/hooks'
import { useI18nContext } from '@shared-snap/i18n/i18n-react'

const snapIframePages = [HOUSEKEEPING.INDEX, ISSUES.INDEX, TASKBOARD.INDEX, TASKBOARD.APALEO]

export default function BasePage() {
    const { locale } = useI18nContext()
    const history = useHistory()
    const location = useLocation()
    const integrationParams = useRecoilValue(integrationParamsAtom)
    const { user, organization } = useContext(AuthContext)
    const { hasAccess, showUpgradeModal, notAccessibleFeature, availableUpgrades, subscriptionsLoaded } = useContext(SubscriptionContext)
    const [showModal, setShowModal] = useState(hasAccess && showUpgradeModal)
    const { isFeatureOn } = useFeatureToggle()
    const iframeRef = useRef<HTMLIFrameElement>(null)
    const [iframeSrc, setIframeSrc] = useState<string | null>(null)

    const snap = isFeatureOn('snap')
    const isSnapIframePage = snapIframePages.includes(location.pathname)

    useTracesIntegrationParams()

    useEffect(() => {
        setShowModal(!hasAccess && showUpgradeModal)
    }, [hasAccess, showUpgradeModal])

    const areasUIEvents = {
        newAreaButtonClick: () => {
            history.push(SETTINGS.UNITS.NEW)
        },
        openEditAreaDialog: (id: string) => {
            history.push(SETTINGS.UNITS.EDIT.GO_TO(id))
        },
        openDeleteAreasDialog: () => {
            history.push(SETTINGS.UNITS.DELETE_AREAS)
        }
    }

    const rulesUIEvents = {
        newRuleButtonClick: () => {
            history.push('/settings/rules/new')
        },
        openEditRuleDialog: (id: string) => {
            history.push(`/settings/rules/${id}/edit`)
        },
        openRuleAreasDialog: (id: string) => {
            history.push(`/settings/rules/${id}/areas`)
        }
    }
    const superUserRoutes = user!.authSuper
        ? [
              <Route key="accounts" path="/partner/accounts" component={AccountsPage} />,
              <Route key="dashboard" path="/partner/dashboard" component={DashboardPage} />
          ]
        : []

    const adminUserRoutes =
        user!.authRole === 'admin'
            ? [
                  <Route key="users" path="/settings/users" component={UsersPage} />,
                  <Route key="account" path="/settings/account" component={AccountPage} />,
                  <Route key="guests" path="/settings/guests" component={GuestsPage} />
              ]
            : []

    const sendParams = () => {
        iframeRef.current?.contentWindow?.postMessage({ path: location.pathname + location.search, integrationParams, lang: locale }, '*')
    }
    useEffect(() => {
        if (snap && isSnapIframePage && !iframeSrc) {
            setIframeSrc(`/snap/${location.pathname}${location.search}`)
        }
    }, [snap, isSnapIframePage, iframeSrc])

    useEffect(() => {
        const handleIframeReady = (event: MessageEvent) => {
            if (event.data.type === 'iframe-ready') {
                sendParams()
            }
        }

        window.addEventListener('message', handleIframeReady)
        return () => window.removeEventListener('message', handleIframeReady)
    }, [integrationParams, location.pathname])

    useEffect(() => {
        if (iframeRef.current && snap && isSnapIframePage) {
            sendParams()
        }
    }, [location.pathname, location.search, snap, locale, isSnapIframePage, integrationParams])

    if (!subscriptionsLoaded) {
        return <LoadingView />
    }

    return (
        <Suspense fallback={<LoadingView />}>
            <UpgradeModal show={showModal} notAccessibleFeature={notAccessibleFeature} availableUpgrades={availableUpgrades} />

            {snap && isSnapIframePage && iframeSrc && (
                <iframe width="100%" height="100%" ref={iframeRef} src={iframeSrc} style={{ border: 'none' }}></iframe>
            )}

            <Switch>
                <Redirect exact from="/" to={HOUSEKEEPING.INDEX} />

                {superUserRoutes.map(route => route)}
                {adminUserRoutes.map(route => route)}

                <Route
                    exact
                    path={REPORTS.ISSUES}
                    component={() => <Reports respWidth={width} respHeight={height} currentUser={user} style={authPageStyle} />}
                />
                <Route path={REPORTS.HOUSEKEEPING} component={ReportsPage} />
                <Route path={REPORTS.OPT_IN} component={OptionalPage} />
                <Route path={REPORTS.WORKLOAD} component={WorkloadPage} />

                {!snap && (
                    <Route
                        exact
                        path={HOUSEKEEPING.INDEX}
                        component={(props: any) => {
                            if (isFeatureOn('display-projection')) {
                                return (
                                    <HousekeepingProjectionView
                                        currentOrganization={organization}
                                        currentUser={user}
                                        respWidth={1024}
                                        respHeight={1000}
                                        {...props}
                                    />
                                )
                            } else {
                                return (
                                    <HouseDashboard
                                        currentOrganization={organization}
                                        currentUser={user}
                                        respWidth={1024}
                                        respHeight={1000}
                                        {...props}
                                    />
                                )
                            }
                        }}
                    />
                )}
                {!snap && <Route path={ISSUES.INDEX} component={IssuesPage} />}
                {!snap && <Route path={TASKBOARD.INDEX} component={TasksPage} />}

                <Route exact path={HOUSEKEEPING.INDEX_NEW} component={HousekeepingDashboard} />

                <AreasProvider areasUIEvents={areasUIEvents}>
                    <RulesProvider rulesUIEvents={rulesUIEvents}>
                        <Switch>
                            {/* @ts-ignore */}
                            <ContentRoute path="/settings/rules/new" component={RuleEdit} />
                            {/* @ts-ignore */}
                            <ContentRoute path="/settings/rules/:id/edit" component={RuleEdit} />
                            <Route path="/settings/rules" component={() => <RulesPage currentOrganization={organization} />} />
                            <Route path={SETTINGS.UNITS.INDEX} component={() => <AreasPage currentOrganization={organization} />} />
                            {!snap && !isSnapIframePage && <Route component={ErrorPage} />}
                        </Switch>
                    </RulesProvider>
                </AreasProvider>

                <Route component={ErrorPage} />
            </Switch>
        </Suspense>
    )
}
