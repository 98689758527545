import React, { forwardRef, useState } from 'react'
import { IconButton as IconButton_DEPRECATED } from '../../../../../components/buttons/IconButton'
import { ActionBarWrap } from './style'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import PriorityButton from '../PriorityButton'
import Alert from '../../../../../components/alerts/Alert'
import { TaskStruct } from '@shared/firestore-structs'
import * as c from '@shared/constants'
import ButtonIcon from 'app/components/buttons/ButtonIcon'
import AssignIcon from 'app/img/assign-task-button.svg?react'
import UncompleteIcon from 'app/img/reuse-issue.svg?react'

interface IActionBar {
    startDate: number
    priority: boolean
    onDeleteClick: () => Promise<void>
    setActionBarFixed: React.Dispatch<React.SetStateAction<boolean>>
    onDueDateChange: (date: Date) => Promise<void>
    onPriorityChange: () => Promise<void>
    onCompleteClick: () => Promise<void>
    onUncompleteClick: () => Promise<void>
    status: TaskStruct['status']
    onAssignClick: () => void
}

interface ICustomDateInput {
    ref: React.RefObject<HTMLInputElement>
    onClick?: () => void
    setActionBarFixed: () => void
    disabled?: boolean
}

function ActionBar({
    startDate,
    priority,
    status,
    onDeleteClick,
    setActionBarFixed,
    onDueDateChange,
    onPriorityChange,
    onCompleteClick,
    onUncompleteClick,
    onAssignClick
}: IActionBar) {
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const completed = status === c.TASK_COMPLETE

    async function onDeleteConfirm() {
        await onDeleteClick()
        setShowDeleteConfirmation(false)
    }

    return (
        <>
            {showDeleteConfirmation && (
                // FIXME define types
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <Alert
                    title={'Delete task'}
                    text={'Are you sure you want to delete this task?'}
                    onConfirm={onDeleteConfirm}
                    onCancel={() => setShowDeleteConfirmation(false)}
                />
            )}
            <ActionBarWrap>
                <IconButton_DEPRECATED
                    className="btn btn-xs btn-circle btn-icon border-0 btn-specta-gray bg-hover-light text-hover-dark-50"
                    onClick={() => setShowDeleteConfirmation(true)}
                    icon="trash"
                    iconsize="icon-ms"
                    tooltip={{
                        text: 'Delete',
                        position: 'top'
                    }}
                />

                <div>
                    {/* FIXME define types */}
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <DatePicker
                        disabled={completed}
                        dateFormat="eee, MMM dd, yyyy"
                        selected={new Date(startDate)}
                        minDate={moment().startOf('day').toDate()}
                        onChange={onDueDateChange}
                        popperPlacement={'bottom'}
                        onClickOutside={() => setActionBarFixed(false)}
                        customInput={
                            <CustomDateInput
                                ref={React.createRef()}
                                setActionBarFixed={() => setActionBarFixed(true)}
                                disabled={completed}
                            />
                        }
                    />
                </div>

                <PriorityButton disabled={completed} priority={priority} onPriorityChange={onPriorityChange} />

                <ButtonIcon onClick={onAssignClick} tooltipText="Assign" disabled={completed}>
                    <AssignIcon />
                </ButtonIcon>

                {completed ? (
                    <ButtonIcon onClick={onUncompleteClick} tooltipText="Uncomplete">
                        <UncompleteIcon />
                    </ButtonIcon>
                ) : (
                    <IconButton_DEPRECATED
                        className="btn btn-xs btn-circle btn-icon border-0 btn-specta-gray bg-hover-light text-hover-dark-50"
                        onClick={onCompleteClick}
                        icon="checkmark"
                        iconsize="icon-nm"
                        tooltip={{
                            text: 'Complete task',
                            position: 'top'
                        }}
                    />
                )}
            </ActionBarWrap>
        </>
    )
}

const CustomDateInput = forwardRef<HTMLInputElement, ICustomDateInput>(({ onClick, setActionBarFixed, disabled = false }, ref) => {
    function onIconClick(e: React.MouseEvent<HTMLButtonElement>) {
        if (disabled) return
        e.preventDefault()
        e.stopPropagation()
        onClick && onClick()
        setActionBarFixed()
    }

    return (
        <IconButton_DEPRECATED
            style={{
                cursor: disabled ? 'default' : 'pointer'
            }}
            disabled={disabled}
            className={`btn btn-xs btn-circle btn-icon border-0 btn-specta-gray ${disabled ? '' : 'bg-hover-light text-hover-dark-50'}`}
            onClick={onIconClick}
            icon="duedate"
            iconsize="icon-ms"
            tooltip={{
                text: 'Change due date',
                position: 'top'
            }}
        />
    )
})

CustomDateInput.displayName = 'CustomDateInput'

export default ActionBar
